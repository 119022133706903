<template>
  <div class="row mx-0" id="code-wrapper">
    <div class="col-12 col-md-10 mx-auto">
      <div v-if="!editActive">
        <label>Einladungs-Code</label>
        <input id="code" v-model="tempCode" class="form-control">
        <button id="submit-code" class="btn btn-olive mt-2" @click="getData">Anmeldung freischalten</button>
        <ERRORS class="mt-4" :errors="code_error"></ERRORS>
        <p style="font-size: 0.9em;padding-top: 1em;">Wir bitten euch, die Anmeldung bis am 31. Dezember 2024 auszufüllen.</p>
      </div>
      <div v-else>
        <div v-if="already">
          <p class="already">{{ this.tempGroup.guests.length > 1 ? 'Ihr habt euch' : 'Du hast dich' }} bereits angemeldet. Bei Fragen stehen {{ this.tempGroup.guests.length > 1 ? 'euch' : 'dir' }}  unsere Tätschmeister jederzeit gerne zur Verfügung.</p>
        </div>
        <div v-else>
          <div class="row mb-2">
            <div class="col-12">
              <p>Einladung für: {{ this.tempGroup.name }}<br>Maximal: {{ this.tempGroup.guests.length }} {{ personText }}</p>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12">
              <label>Anzahl Zusagen</label>
              <input v-model="tempReg.amount" class="form-control">
              <div class="alert-danger mt-3" v-if="(tempGroup !== null && tempGroup.guests.length < tempReg.amount)">
                <p>Es dürfen maximal {{ this.tempGroup.guests.length }} zusagen</p>
              </div>
            </div>
          </div>
          <div class="row my-4" v-if="tempReg.amount > 0 && tempGroup.type === 1">
            <div class="col-12 col-lg-6">
              <label>Auswahl Menü:</label>
              <div v-for="food in tempEvent.food_types" :key="'newReg-food'+food.id">
                <label>{{ food.name }}</label>
                <input type="number"
                       min="0"
                       class="form-control"
                       @change="changeFoodTypeCount(food, $event)"
                       :value="(tempReg.foodTypes.findIndex(x => x.food_type_id === food.id) !== -1) ? tempReg.foodTypes[tempReg.foodTypes.findIndex(x => x.food_type_id === food.id)].amount : 0">
              </div>
              <div class="alert-danger my-2" v-if="!foodEnough">
                <p>Es müssen genau {{ tempReg.amount }} Menüwünsche gewählt werden</p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <label>Auswahl Anreise:</label>
              <div v-for="avenue in tempEvent.avenues" :key="'newReg-ave'+avenue.id">
                <label>{{ avenue.name }}</label>
                <input type="number"
                       min="0"
                       class="form-control"
                       @change="changeAvenueTypeCount(avenue, $event)"
                       :value="(tempReg.avenues.findIndex(x => x.avenue_id === avenue.id) !== -1) ? tempReg.avenues[tempReg.avenues.findIndex(x => x.avenue_id === avenue.id)].amount : 0">
              </div>
              <div class="alert-danger my-2" v-if="!avenuesEnough">
                <p>Es müssen genau {{ tempReg.amount }} Anreisemöglichkeiten gewählt werden</p>
              </div>
            </div>
            <div class="col-12 my-2">
              <input type="checkbox" id="hotel-room" v-model="wantHotelRoom" />
              <label for="hotel-room" style="display: inline;">{{ hotelRoomText }}</label>
              <span style="font-size: 11px; display: block;">
                Wir nehmen dies auf und melden uns dann zeitig wieder bei {{ this.tempGroup.guests.length > 1 ? 'euch' : 'dir' }}.
              </span>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12">
              <label>Bemerkungen</label>
              <textarea v-model="tempReg.comment" class="form-control"></textarea>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-olive btn-block"
                      @click="createReg"
                      :disabled="buttonDisabled">
                Anmeldung absenden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ERRORS from "./errors";

export default {
  name: "reg.vue",
  components: {ERRORS},
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tempEvent: null,
      tempGroup: null,
      tempCode: this.code,
      editActive: false,
      already: false,
      wantHotelRoom: false,
      code_error: {},
      create_error: {},
      tempReg: {
        amount: 0,
        comment: '',
        avenues: [],
        foodTypes: [],
      }
    }
  },
  mounted() {
    if (this.code.length > 4) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      await axios.post('https://wedding-planner.alessandro-esposito.ch/api/event/3/group', {code: this.tempCode}).then(resp => {
        this.tempEvent = resp.data.event;
        this.tempGroup = resp.data.group;
        this.editActive = true;
        this.code_error = {};
        this.already = resp.data.already;
      }).catch(error => {
        console.log(error);
        this.code_error = error.response.data.errors;
      })
    },
    async createReg() {

      let data = {
        code: this.tempCode,
        ...this.tempReg,
      }

      if(this.wantHotelRoom) {
        data.comment = this.tempReg.comment + ' | ' + this.hotelRoomText;
      }

      await axios.post('https://wedding-planner.alessandro-esposito.ch/api/event/3/group/' + this.tempGroup.id + '/register', data).then(resp => {
        this.create_error = {};
        this.already = true;
      }).catch(error => {
        console.log(error);
        this.create_error = error.response.data.errors;
      })
    },
    changeFoodTypeCount(food, event) {
      let index = this.tempReg.foodTypes.findIndex(x => x.food_type_id === food.id);
      if (index !== -1) {
        this.tempReg.foodTypes[index].amount = event.target.value;
      } else {
        this.tempReg.foodTypes.push({
          food_type_id: this.tempEvent.food_types[this.tempEvent.food_types.findIndex(x => x.id === food.id)].id,
          amount: event.target.value,
        })
      }
    },
    changeAvenueTypeCount(avenue, event) {
      let index = this.tempReg.avenues.findIndex(x => x.avenue_id === avenue.id);
      if (index !== -1) {
        this.tempReg.avenues[index].amount = event.target.value;
      } else {
        this.tempReg.avenues.push({
          avenue_id: this.tempEvent.avenues[this.tempEvent.avenues.findIndex(x => x.id === avenue.id)].id,
          amount: event.target.value,
        })
      }
    }
  },
  computed: {
    foodEnough() {
      return this.tempReg.foodTypes.map(item => item.amount).reduce((prev, current) => Math.abs(parseInt(prev)) + Math.abs(parseInt(current)), 0) === parseInt(this.tempReg.amount);
    },
    avenuesEnough() {
      return this.tempReg.avenues.map(item => item.amount).reduce((prev, current) => Math.abs(parseInt(prev)) + Math.abs(parseInt(current)), 0) === parseInt(this.tempReg.amount);
    },
    valueIsNegative() {
      let val1 = this.tempReg.avenues.filter(function (elem) {
        if (elem.amount < 0) return elem.amount;
      });

      let val2 = this.tempReg.foodTypes.filter(function (elem) {
        if (elem.amount < 0) return elem.amount;
      });
      
      return (val1.length > 0 || val2.length > 0)
    },
    buttonDisabled() {
      let groupCond = (this.tempGroup !== null && this.tempGroup.guests.length < this.tempReg.amount);
      if (this.tempGroup !== null && this.tempGroup.type === 0) {
        return groupCond;
      }
      return !this.avenuesEnough || !this.foodEnough || groupCond || this.valueIsNegative;
    },
    hotelRoomText() {
      return this.tempGroup.guests.length > 1 
      ? "Wir haben Interesse an einer Übernachtung im Seehotel Sonne Eich"
      : "Ich habe Interesse an einer Übernachtung im Seehotel Sonne Eich"
    },
    personText() {
      return this.tempGroup.guests.length > 1 
      ? "Personen"
      : "Person"
    }
  }
}
</script>
<style lang="scss">
  .alert-danger {
    p {
      padding: 10px;
    }
  }
</style>