<template>
  <div id="wishlist">
    <a id="wishlist__anchor" class="anchor"></a>
    <div class="header">
      <h2>Unsere Wunschliste</h2>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-3 col-xl-3 wish" :class="{'is-gifted':wish.is_fully_gifted}"
           v-for="wish in wishlist.wishes" :key="'wish'+wish.id">
           <div class="wish-wrapper">
            <div class="wish-bg"></div>
              <img :src="wish.image + '?timestamp=' + (Date.now())">
              <h3>{{ wish.name }}</h3>
              <p>{{ wish.description }}<br>
                <span v-if="wish.link !== null && wish.link !== 'null' && wish.link !== ''" v>Diesen Wunsch gibt es <a target="_blank"
                                                                                                  :href="wish.link">hier</a>
                  zu finden.</span></p>
              <p v-if="wish.amount < 1">Unbegrenzt schenkbar</p>
              <p v-else-if="wish.presents_left > 0">Noch {{ wish.presents_left }}x zu schenken</p>
              <p v-else>Wunsch wurde bereits erfüllt</p>
              <button class="btn btn-olive" @click="openModal(wish)" :disabled="wish.is_fully_gifted">schenken</button>
           </div>
      </div>
    </div>
    <div class="modal fade bd-example-modal-lg" id="giftModal" tabindex="-1" role="dialog"
         aria-labelledby="giftModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Wunsch erfüllen</h5>
            <button type="button" class="close" @click="toggleModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="!success">
              <div id="gift-form" class="row">
                <div class="col-12 mt-2 mb-2">
                  <h3>{{ tempWish.name }}</h3>
                </div>
                <div class="col-12 my-2">
                  <label>Name</label>
                  <input class="form-control" v-model="present.name" required>
                </div>
                <div class="col-12 my-2">
                  <label>Adresse</label>
                  <input type="text" class="form-control" v-model="present.address" required>
                </div>
                <div class="col-4 my-2">
                  <label>PLZ</label>
                  <input class="form-control" v-model="present.zip" required>
                </div>
                <div class="col-8 my-2">
                  <label>Ort</label>
                  <input class="form-control" v-model="present.city" required>
                </div>
                <div class="col-12 my-2">
                  <label>Email (optional)</label>
                  <input class="form-control" v-model="present.email">
                </div>
                <div class="col-12 my-2">
                  <label>Code (der Gästegruppe, optional)</label>
                  <input class="form-control" v-model="present.code">
                </div>
                <div class="col-12 my-2">
                  <label>{{
                      (tempWish.amount > 0) ? 'Stück Anzahl, max: ' + tempWish.presents_left : 'Betrag in CHF'
                    }}</label>
                  <input class="form-control" v-model="present.amount">
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <ERRORS :errors="errors"></ERRORS>
                </div>
              </div>
              <div id="gift-iban" class="row" v-if="tempWish.amount < 1">
                <div class="col-12 col-md-6">
                  <p>Unsere Bankverbindung:<br>
                    <b>IBAN:<br></b>
                    {{ wishlist.iban }}<br>
                  </p>
                </div>
                <div class="col-12 col-md-6">
                  <p><b>Zugunsten von:</b><br>
                    {{ wishlist.receiver }}<br>
                    {{ wishlist.address }}<br>
                    {{ wishlist.zip }} {{ wishlist.city }}</p>
                </div>
              </div>
            </div>
            <div class="row" id="gift-success" v-else>
              <div class="col-12 alert-success" style="padding: 10px; border-radius: 10px;">
                <p>Das Geschenk wurde erfolgreich gesendet</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="toggleModal">
              {{ (success) ? 'schliessen' : 'abbrechen' }}
            </button>
            <button type="button" class="btn btn-olive" v-if="!success" :disabled="disableSend" @click="giftWish">Wunsch
              erfüllen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ERRORS from "./errors";

export default {
  name: "Wishlist",
  components: {ERRORS},
  data() {
    return {
      wishlist: [],
      tempWish: {
        name: '',
        wish_id: -1,
        amount: -100,
        presentsLeft: -1,
      },
      errors: {},
      present: {
        name: '',
        address: '',
        zip: '',
        city: '',
        amount: '',
        email: '',
        code: '',
      },
      success: false,
    }
  },
  created() {
    this.getWishList();
  },
  methods: {
    async getWishList() {
      await axios.get('https://wedding-planner.alessandro-esposito.ch/api/event/3/wishlist').then(resp => {
        this.wishlist = resp.data.wishlist;
      }).catch(error => {
        console.log(error);
      });
    },
    openModal(wish) {
      this.tempWish = wish;
      if (this.$cookies.isKey('present')) {
        this.present = this.$cookies.get('present');
        this.present.amount = '';
      }
      $('#giftModal').modal('show');
    },
    async giftWish() {
      await axios.post('https://wedding-planner.alessandro-esposito.ch/api/event/3/wish/' + this.tempWish.id + '/gift', this.present).then(resp => {
        this.success = true;
        this.errors = {};
        this.wishlist = resp.data.wishlist;
        this.$cookies.set('present', this.present);
        this.resetWish();
        this.resetPresent();
      }).catch(error => {
        this.errors = error.response.data.errors;
      });
    },
    toggleModal() {
      $('#giftModal').modal('hide');
      this.resetWish();
      this.resetPresent();
      this.success = false;
    },
    resetWish() {
      this.tempWish = {
        name: '',
        wish_id: -1,
        amount: -100,
      };
    },
    resetPresent() {
      this.present = {
        name: '',
        address: '',
        zip: '',
        city: '',
        amount: '',
        email: '',
        code: '',
      }
    }
  },
  computed: {
    disableSend() {
      for (let item in this.present) {
        if (this.present[item].length === 0 && item !== 'email' && item !== 'code') {
          return true;
        }
      }
      return false;
    }
  }
}
</script>