window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from 'vue/dist/vue.common.js';
import VueCookies from 'vue-cookies'

window.Vue = Vue;
Vue.use(VueCookies);
Vue.$cookies.config('365d', '', '', true);

Vue.component('wishlist', require('./components/wishlist').default);
Vue.component('reg', require('./components/reg').default);


const app = new Vue({}).$mount('#app');