<template>
  <div class="errors alert-danger" style="padding: 10px; border-radius: 10px;" v-if="Object.keys(errors).length > 0">
    <p class="error-title my-2 ml-4">Ups, deine Angaben sind inkorrekt</p>
    <ul v-for="(err, prop, index) in errors" :key="prop">
      <li class="error-text" style="list-style-type:circle" v-for="(e, index) in err" :key="prop+'-'+index">
        {{ e }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ERRORS",
  props: {
    errors: {
      default: {},
      type: Object,
    }
  }
}
</script>
<style>
  .error-title {
    color: white!important;
  }
  .error-text {
    color: white;
  }
</style>